import React from 'react'
import './App.css';
import axios from 'axios';
import {useParams} from 'react-router-dom'

class App extends React.Component { 

  constructor(props) {
    super(props);
    this.state = {
      isUnsunscribed: false
    }
  }
  
  // let [initVal, setinitVal] = useState(false);
  // if(!initVal) {
  //   setinitVal(true);
  //   unsubscribe();
  // }
    unsubscribe = () => {
      
      this.setState({isUnsunscribed: true});

      let url = window.location.pathname;
      let person_id = url.substring(url.lastIndexOf('/') + 1);
      if(person_id) {
        axios({
              method: 'get',
              url: 'http://laravel.techliance.com/api/v6/unsubscribe/' + person_id,
              // url: 'http://laravel.techliance.com/api/v6/unsubscribe/6a4bc9e1-92da-47e2-a105-3783664fc16c',
              // url: 'http://api.mazibook.com/api/unsubscribe',
              // data: {}
          })
          .then(
            // res => this.setState({ recipes: res.data })
            ).catch((error) => {
              // console.log('error: unable to unsubscribe', error)
            }
            );
      }
    }
  
  componentDidMount() {
    if(!this.isUnsunscribed) {
      this.unsubscribe();
    }
  }
  render() {
    

    // const [searchParams] = useSearchParams();
    // searchParams.get("__firebase_request_key")
  
    return (
      <div className="App">
        <header className="App-header">
          <a href='https://www.mazibook.com'>
            <img src="logo.png" className="mazibook" alt="mazibook" />
          </a>
        </header>
        <main>
          {/* <div style={{width: '100%',height:0,paddingBottom: '25%',position:'relative',}}>
            <img src={url} />
          </div> */}
          <div className='unsub'>
            <svg xmlns="http://www.w3.org/2000/svg" style={{width:100,height:100,color:"#6bab1f"}} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <p className='msg'>You have successfully unsubscribed!</p>
          </div>
        </main>
        <footer className='footer'>
          <span>© {new Date().getFullYear()} by Mazibook LLC</span>
          <a href="https://www.mazibook.com/priviacy-agreement" target="_self"><span className="color_11"><span style={{}}>Privacy Agreement</span></span></a>
        </footer>
      </div>
    );
  }
  
}



export default App;
